.PageHeader{
    font-size: 24px;
    font-weight: bold;
    padding-top: 50px;
    
    // color:#000082;
}
.horizontalLine {
    background: #000082;
    height: '3px';
    width: 100%
}

.buttonNewProject{
    background-color: #000082;
    color:white;
    margin-top: 10px;
    width: 140px;
}
.buttonTab{
    
    color: #000082;
    background-color:white;
    margin-top: 10px;
    border-bottom:3px solid #000082 ;
    width:auto;
    margin-right:30px;

}
.searchBox{
    width:400px;
    align-self: flex-end;
    display: flex;
}
.actionIconButton{
    color: grey;
    background-color: white;
    width: 24px;
    min-width: 24px;
    height: 24px;
    border:1px solid lightgray;
    margin-right: 2px;
    align-items: center;
}
.overviewIconButton{
    color: green;
    // background-color: white;
    width: 24px;
    min-width: 24px;
    height: 24px;
    
    margin-right: 2px;
    align-items: center;

}
.Draft{
    background-color: yellow;
    color: grey;
    text-align: center;
    border-radius: 5px;
    padding: 2px 5px 2px 5px;
}
.Active{
    border:1px solid green;
    background-color: white;
    color:green;
    text-align: center;
    border-radius: 5px;
    padding: 2px 5px 2px 5px;
}
.Archived{
    background-color: lightgrey;
    color: gray;
    text-align: center;
    border-radius: 5px;
    padding: 2px 5px 2px 5px;
}