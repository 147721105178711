.PageHeader {
    font-size: 24px;
    font-weight: bold;
    padding-top: 50px;
    // color:#000082;
}

.horizontalLine {
    background: #000082;
    height: '3px';
    width: 100%
}

.buttonNewProject {
    background-color: #000082;
    color: white;
    margin-top: 50px;
    width: 140px;
}

.buttonSave {
    background-color: #000082;
    color: white;
    margin-right: 20px;
    width: auto;
}

.buttonCancel {
    color: #000082;
    background-color: white;
    width: 140px;
    border-color: #000082;
}

.buttonCancel:hover {
    background-color: #000082;
    color: white;
}


.contentHeader {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
}

.padding {
    padding-left: 100px;
    padding-right: 100px;
}

.btnAddItem {
    background-color: #000082;
    color: white;
    // margin-right: 20px;

}

.actionIconButton {
    color: grey;
    background-color: white;
    width: 24px;
    min-width: 24px;
    height: 24px;
    border: 1px solid lightgray;
    margin-right: 2px;
    align-items: center;

}

#tacContent {
    background-color: #777676;
    outline: 1px solid red;
    width: 100%;
    height: 100%;
}

.link {
    color: #000082;
    text-decoration: underline;
    padding-right: 20px;
}