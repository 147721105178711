.siteHeader {
    display: flex;
    width: 100%;
    align-items: center;
}

.title {
    // margin-right: 5rem;
    flex: 1;
    font-weight: bold;
    text-align: center;
    color: white;
    font-size: 28px;
    margin-right: 170px;
}

.header {
    height: 105px;
    width: 100%;
    display: flex;
    background: #000082;
    background-repeat: no-repeat;
}
.logo{
    width:150px;
    padding-left:20px;
}
